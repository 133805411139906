import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { type GridSortModel } from '@mui/x-data-grid';
import { useAtomValue, useStore, type getDefaultStore, type PrimitiveAtom } from 'jotai';
import { dataGridRowsPerPageAtom } from 'components';
import {
  clientsAtoms,
  clustersAtoms,
  countriesAtoms,
  currenciesAtoms,
  ENormalizeGroups,
  EPlayerFilter,
  gamesAtoms,
  operatorsAtoms,
  type IAccountingFilterData,
} from 'hooks/useAccountingFilters';
import { useSetLoading } from 'hooks/useLoading';
import { useUserPreferences } from 'hooks/useUserPreferences';
import {
  parseBigNumber,
  parseCountryCode,
  parseCurrencyNumber,
  parseDateStringTz,
  parseDateToUTC,
  showError,
} from 'utils';
import { appliedFiltersAtom, type IAppliedFilters } from './accountingDataAtoms';
import { GET_TRANSACTIONS_QUERY } from './accountingQuery';

interface INormalizableValues {
  ggr?: number;
  debitTotal?: number;
  refundTotal?: number;
  creditTotal?: number;
  totalBuyBet: number;
  frbBetTotal?: number;
  frbWinTotal?: number;
}

interface IRawRecord {
  clusterLabel?: string;
  currency: string;
  country?: string;
  operatorLabel: string;
  clientLabel?: string;
  slotLabel: string;
  transactionsCount: number;
  totalBuyCount: number;
  values: INormalizableValues;
  normalizedValues?: INormalizableValues;
  day?: string;
  frbCount?: number;
}

export interface IAccountingSummary {
  ggr: string;
  debitTotal: string;
  creditTotal: string;
  refundTotal: string;
  totalBuyBet: string;
  totalBuyCount: string;
  transactionsCount: string;
  frbCount: string;
  frbBetTotal: string;
  frbWinTotal: string;
}

export interface IReportTransactionsSummary {
  ggrSummary: number;
  debitTotalSummary: number;
  creditTotalSummary: number;
  refundTotalSummary: number;
  totalBuyBetSummary: number;
  totalBuyCountSummary: number;
  transactionsCountSummary: number;
  frbBetTotalSummary: number;
  frbCountSummary: number;
  frbWinTotalSummary: number;
}

interface IAccountingData {
  reportTransactions: {
    items: IRawRecord[];
    skip: number;
    take: number;
    totalCount: number;
    groupBy: ENormalizeGroups;
    summary?: IReportTransactionsSummary;
    normalization?: {
      baseCurrency: string;
      currencyDate: string;
    };
  };
}

interface IRowData {
  id: string;
  clusterLabel?: string;
  clientLabel?: string;
  operatorLabel: string;
  slotLabel: string;
  currency: string;
  country?: string;
  debitTotal: string;
  creditTotal: string;
  refundTotal: string;
  transactionsCount: string;
  totalBuyBet: string;
  totalBuyCount: string;
  ggr: string;
}

export const playersMap = {
  [EPlayerFilter.ALL]: undefined,
  [EPlayerFilter.TESTERS_ONLY]: true,
  [EPlayerFilter.REAL_ONLY]: false,
};

export const parseAccountingFilters = (appliedFilters: IAppliedFilters, store: ReturnType<typeof getDefaultStore>) => {
  const getSelectableAllFilters = (selectedArr: string[], dataAtom: PrimitiveAtom<IAccountingFilterData[]>) => {
    const dataArr = store.get(dataAtom);
    if (selectedArr.length === 0 || dataArr.length === selectedArr.length) {
      return undefined;
    }
    if (selectedArr.length > dataArr.length / 2) {
      return { notIn: dataArr.filter((item) => !selectedArr.includes(item.id)).map((item) => item.id) };
    }
    return { in: selectedArr };
  };

  const filters: Record<string, object | boolean | void> = {
    clusterId: getSelectableAllFilters(appliedFilters.clusters, clustersAtoms.data),
    clientId: getSelectableAllFilters(appliedFilters.clients, clientsAtoms.data),
    operatorId: getSelectableAllFilters(appliedFilters.operators, operatorsAtoms.data),
    slotId: getSelectableAllFilters(appliedFilters.games, gamesAtoms.data),
    country: getSelectableAllFilters(appliedFilters.countries, countriesAtoms.data),
    currency: getSelectableAllFilters(appliedFilters.currencies, currenciesAtoms.data),
    createdAt: {
      gte: parseDateToUTC(appliedFilters.dateFrom, appliedFilters.timezone),
      lte: parseDateToUTC(appliedFilters.dateTo, appliedFilters.timezone, true),
    },
    tester: playersMap[appliedFilters.players],
  };

  return filters;
};

const parseAccountingData = (data: IRawRecord[], normalizeCurrency = '', timezone: string): IRowData[] => {
  return data.map((item) => {
    const values = item.normalizedValues || item.values;
    return {
      id: crypto.randomUUID(),
      clusterLabel: item.clusterLabel,
      clientLabel: item.clientLabel,
      operatorLabel: item.operatorLabel,
      slotLabel: item.slotLabel,
      currency: item.currency,
      country: item.country && parseCountryCode(item.country),
      transactionsCount: parseBigNumber(item.transactionsCount),
      totalBuyCount: parseBigNumber(item.totalBuyCount),
      totalBuyBet: parseCurrencyNumber(values.totalBuyBet, normalizeCurrency),
      debitTotal: parseCurrencyNumber(values.debitTotal, normalizeCurrency),
      creditTotal: parseCurrencyNumber(values.creditTotal, normalizeCurrency),
      frbBetTotal: parseCurrencyNumber(values.frbBetTotal, normalizeCurrency),
      frbWinTotal: parseCurrencyNumber(values.frbWinTotal, normalizeCurrency),
      ggr: parseCurrencyNumber(values.ggr, normalizeCurrency),
      refundTotal: parseCurrencyNumber(values.refundTotal, normalizeCurrency),
      frbCount: parseBigNumber(item.frbCount),
      ...(item.day && { day: parseDateStringTz(item.day, timezone, { format: 'YYYY-MM-DD' }) }),
    };
  });
};

export const useAccountingData = () => {
  const store = useStore();
  const { startLoading, endLoading } = useSetLoading();
  const [getData] = useLazyQuery<IAccountingData>(GET_TRANSACTIONS_QUERY);
  const appliedFilters = useAtomValue(appliedFiltersAtom);
  const rowsPerPage = useAtomValue(dataGridRowsPerPageAtom);

  const {
    userPreferences: { timezone },
  } = useUserPreferences();

  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<GridSortModel>([]);
  const [data, setData] = useState<IRowData[]>([]);
  const [summary, setSummary] = useState<IAccountingSummary | undefined>(undefined);
  const [totalDataCount, setTotalDataCount] = useState(-1);
  const [groupBy, setGroupBy] = useState<ENormalizeGroups>(ENormalizeGroups.NONE);

  const setSortAndClearPage = useCallback((...params: Parameters<typeof setSort>) => {
    setPage(0);
    setSort(...params);
  }, []);
  useEffect(() => store.sub(appliedFiltersAtom, () => setPage(0)), []);
  useEffect(() => {
    if (appliedFilters) {
      const sortKey = sort[0]?.field;
      const prefix = appliedFilters.normalizeEnabled ? 'normalizedValues' : 'values';
      const normalizedSortKey: Record<string, string> = {
        ggr: `${prefix}.ggr`,
        debitTotal: `${prefix}.debitTotal`,
        creditTotal: `${prefix}.creditTotal`,
        refundTotal: `${prefix}.refundTotal`,
        totalBuyBet: `${prefix}.totalBuyBet`,
        frbBetTotal: `${prefix}.frbBetTotal`,
        frbWinTotal: `${prefix}.frbWinTotal`,
      };
      startLoading('fetchAccountingData');
      getData({
        fetchPolicy: 'network-only',
        variables: {
          filter: parseAccountingFilters(appliedFilters, store),
          groupBy:
            appliedFilters.normalizeGroupBy === ENormalizeGroups.NONE ? undefined : appliedFilters.normalizeGroupBy,
          orderBy: sortKey in normalizedSortKey ? normalizedSortKey[sortKey] : sortKey,
          orderDirection: sort[0]?.sort,
          take: rowsPerPage,
          skip: page * rowsPerPage,
          normalized: appliedFilters.normalizeEnabled,
          timezone: appliedFilters.timezone,
          ...(appliedFilters.normalizeEnabled && {
            normalization: {
              currencyDate: parseDateToUTC(appliedFilters.currencyDate, appliedFilters.timezone),
              baseCurrency: appliedFilters.normalizeCurrency,
            },
          }),
        },
      })
        .then((response) => {
          if (response.data?.reportTransactions) {
            const { summary, totalCount, items, groupBy, normalization } = response.data.reportTransactions;
            const normalizeCurrency = normalization?.baseCurrency || '';
            const mappedData: IRowData[] = parseAccountingData(
              items,
              normalizeCurrency,
              appliedFilters.timezone || timezone,
            );
            setTotalDataCount(totalCount);
            setData(mappedData);
            setGroupBy(groupBy || ENormalizeGroups.NONE);
            setSummary(
              summary && {
                ggr: parseCurrencyNumber(summary.ggrSummary, normalizeCurrency),
                debitTotal: parseCurrencyNumber(summary.debitTotalSummary, normalizeCurrency),
                creditTotal: parseCurrencyNumber(summary.creditTotalSummary, normalizeCurrency),
                refundTotal: parseCurrencyNumber(summary.refundTotalSummary, normalizeCurrency),
                totalBuyBet: parseCurrencyNumber(summary.totalBuyBetSummary, normalizeCurrency),
                totalBuyCount: parseBigNumber(summary.totalBuyCountSummary, 0),
                transactionsCount: parseBigNumber(summary.transactionsCountSummary, 0),
                frbCount: parseBigNumber(summary.frbCountSummary, 0),
                frbBetTotal: parseBigNumber(summary.frbBetTotalSummary, 0),
                frbWinTotal: parseBigNumber(summary.frbWinTotalSummary, 0),
              },
            );
          } else if (response.error) {
            showError(response.error);
          }
          endLoading('fetchAccountingData');
        })
        .catch((err) => {
          showError(err);
          endLoading('fetchAccountingData');
        });
    }
  }, [appliedFilters, sort, page, rowsPerPage]);

  return {
    data: data || [],
    totalDataCount,
    page,
    setPage,
    sort,
    setSort: setSortAndClearPage,
    summary,
    groupBy,
  };
};
