import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { TPerm } from 'auth';
import dayjs from 'dayjs';
import { Box, ButtonInputForm, type IButtonInputsFormAppliedFilter, type IButtonInputsFormInputType } from 'components';
import { EPlayerFilter, useButtonInputsFormQueryParams, usePerms, type IDefaultFiltersData } from 'hooks';
import { mapButtonInputsToCurrency } from 'utils';
import { getRoundTypeOptions } from 'utils/roundTypes';

const generateInputsData = (perms: TPerm) => {
  const data = [
    perms.clusters && { type: 'multiselect', key: 'clusters', translateKey: 'playerBrowser.clusters' },
    perms.clients && { type: 'multiselect', key: 'clients', translateKey: 'playerBrowser.clients' },
    perms.operators && { type: 'multiselect', key: 'operators', translateKey: 'playerBrowser.operators' },
    { type: 'multiselect', key: 'games', translateKey: 'playerBrowser.games' },
    { type: 'uuid', key: 'playerId', translateKey: 'playerBrowser.playerId' },
    { type: 'string', key: 'playerExternalId', translateKey: 'playerBrowser.playerExternalId' },
    { type: 'uuid', key: 'playerInternalId', translateKey: 'playerBrowser.playerInternalId' },
    { type: 'number', key: 'totalBet', translateKey: 'playerBrowser.totalBet' },
    { type: 'number', key: 'totalWin', translateKey: 'playerBrowser.totalWin' },
    { type: 'number', key: 'totalRefund', translateKey: 'accounting.totalRefund' },
    {
      type: 'date',
      key: 'createdAt',
      translateKey: 'playerBrowser.betTime',
      config: {
        defaultValue: {
          operator: 'greater',
          value: dayjs().subtract(7, 'days').format(),
          value2: '',
        },
      },
    },
    { type: 'multiselect', key: 'currencies', translateKey: 'playerBrowser.currencies' },
    { type: 'multiselect', key: 'countries', translateKey: 'playerBrowser.countries' },
    {
      type: 'select',
      key: 'tester',
      translateKey: 'accounting.Players',
      config: {
        defaultValue: {
          value: EPlayerFilter.REAL_ONLY,
        },
      },
    },
    {
      type: 'currencyNormalization',
      key: 'currencyNormalization',
      translateKey: 'playerBrowser.currencyNormalization',
    },
  ].filter(Boolean) as { type: IButtonInputsFormInputType; key: string; translateKey: string }[];

  return data;
};

const initialShowingInputs = [
  'clusters',
  'clients',
  'operators',
  'games',
  'playerExternalId',
  'totalBet',
  'totalWin',
  'totalRefund',
  'createdAt',
  'currencyNormalization',
  'tester',
];

interface IProps {
  onAccept: (filters: IButtonInputsFormAppliedFilter[]) => void;
  selectOptions: IDefaultFiltersData | null;
  loading: boolean;
}

export const PlayerBrowserFilters = ({ onAccept, loading, selectOptions }: IProps) => {
  const { setQueryParams, initialState } = useButtonInputsFormQueryParams(window.location.search, {
    parseParam: {
      bet: (state) => ({ ...state, ...(state.value && { value: state.value / 100 }) }),
      win: (state) => ({ ...state, ...(state.value && { value: state.value / 100 }) }),
      refund: (state) => ({ ...state, ...(state.value && { value: state.value / 100 }) }),
    },
  });

  const [showingInputs, setShowingInputs] = useState(() => {
    return [...initialShowingInputs, ...Object.keys(initialState).filter((key) => !initialShowingInputs.includes(key))];
  });

  const { t } = useTranslation();
  const perms = usePerms();

  const inputsData = useMemo(() => generateInputsData(perms), [perms]);

  const handleAccept = useCallback((filters: IButtonInputsFormAppliedFilter[]) => {
    const mappedFilters = mapButtonInputsToCurrency(filters, ['bet', 'win', 'refund']);

    setQueryParams(mappedFilters);
    onAccept(mappedFilters);
  }, []);

  return (
    <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
      <ButtonInputForm
        inputsData={inputsData}
        showingInputs={showingInputs}
        setShowingInputs={setShowingInputs}
        initialState={initialState}
        loading={loading}
        selectOptions={{
          ...selectOptions,
          roundType: getRoundTypeOptions(t),
          tester: [
            { name: t('accounting.testersPlayersOnly'), id: EPlayerFilter.TESTERS_ONLY },
            { name: t('accounting.realPlayersOnly'), id: EPlayerFilter.REAL_ONLY },
            { name: t('accounting.allPlayers'), id: EPlayerFilter.ALL },
          ],
        }}
        onAccept={handleAccept}
      />
    </Box>
  );
};

export default PlayerBrowserFilters;
